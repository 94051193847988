// extracted by mini-css-extract-plugin
export var adBox = "_2024-kullanvainaja-module--adBox--aba1b";
export var adImageBox = "_2024-kullanvainaja-module--adImageBox--0205c";
export var adsList = "_2024-kullanvainaja-module--adsList--be8c4";
export var background2024 = "_2024-kullanvainaja-module--background2024--b724b";
export var columnInner = "_2024-kullanvainaja-module--columnInner--4a7e7";
export var contentBox2024 = "_2024-kullanvainaja-module--contentBox2024--aad23";
export var contentPadding = "_2024-kullanvainaja-module--contentPadding--f85b4";
export var contentStyle2024 = "_2024-kullanvainaja-module--contentStyle2024--b2eb1";
export var hoverable2024 = "_2024-kullanvainaja-module--hoverable2024--ce05d";
export var lisainfo2024 = "_2024-kullanvainaja-module--lisainfo2024--b120f";
export var logo2024 = "_2024-kullanvainaja-module--logo2024--daed7";
export var root2024 = "_2024-kullanvainaja-module--root2024--02afe";
export var showsColumn2024 = "_2024-kullanvainaja-module--showsColumn2024--47dab";
export var showsStyle2024 = "_2024-kullanvainaja-module--showsStyle2024--212f1";
export var titleImg = "_2024-kullanvainaja-module--titleImg--9600c";
export var toggleBox = "_2024-kullanvainaja-module--toggleBox--41775";
export var toggleBoxGrid = "_2024-kullanvainaja-module--toggleBoxGrid--f31f8";
export var toggleTitle = "_2024-kullanvainaja-module--toggleTitle--480df";