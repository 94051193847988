import React from 'react';
import { Helmet } from 'react-helmet';

import DefaultLayout from '../layouts';
import Metadata from '../components/metadata';
import ToggleBox from '../components/toggle-box';

import * as styles from './2024-kullanvainaja.module.css';

import logo_poas from '../assets/common/ads/poas.svg';
// import logo_tek from '../assets/common/ads/tek-2023-valkoinen.svg';
import logo_tek_slogan from '../assets/common/ads/tek-2023-valkoinen-slogan.svg';
import logo_riemurinne from '../assets/common/ads/riemurinne_netti.png'

import titleImg from '../assets/2024/kaari2.png';
import logo_siniset_bussit from '../assets/common/ads/siniset_bussit_logo_hene.png'
import logo_tamppi from '../assets/common/ads/tamppi_logo.png'
// --- all required data definitions below ---

const sponsors = [
  { href: 'https://www.poas.fi/', src: logo_poas, title: 'POAS' },
  { href: 'https://www.riemurinne.fi/', src: logo_riemurinne, title: "Riemurinne"}
  ,{href: 'https://www.hene.fi', src: logo_siniset_bussit, title: 'Hene'},
  { href: 'https://www.tek.fi', src: logo_tek_slogan, title: 'TEK' },
  { href: 'https://www.tamppi.fi/', src: logo_tamppi, title: 'Tamppi'}
];

const crew = [
  {
    title: 'Bändi',
    members: [
      <strong>Taneli Hongisto - rummut</strong>,
      <strong>Roope Suojanen - Basso</strong>,
      'Emma Brown - laulu',
      'Tuomas Erkkilä - viulu',
      'Tulya Aytekin - laulu',
      'Joonas Nevavuori - saksofoni, poikkihuilu',
      'Milla Tyyskä - koskettimet, saksofoni',
      'Kaisa Silkelä - klarinetti',
      'Aarni Sutelainen - kitara',
    ],
  },
  {
    title: 'Hallitus',
    members: [
      <strong>Henri Kiiski</strong>,
      <strong>Jemina Raukamo</strong>,
      'Emma Brown',
      'Taneli Hongisto',
      'Ulrika Kaara',
      'Sanna Kallio',
      'Erika Kaski',
      'Meiju Koivuniemi',
      'Miika Kotajärvi',
      'Anna Kuisma',
      'Justus Leppäaho',
      'Daniel Lopez',
      'Utu Metsälä',
      'Kamilla Mäntynen',
      'Sara Nortunen',
      'Lauri Pitkäjärvi',
      'Minna Rajala',
      'Jethro Sukkela', 
      'Sisu Suominen',
    ]
  },
  {
    title: 'Hyvinvointi & viihtyvyys',
    members: [
      <strong>Tytti Hyvönen</strong>,
      "Mariela Krokfors",
      "Sara Litmanen",
      "Sara Nortunen",
      "Lauri Pitkäjärvi",
      "Sai Pranay",
      "Jemina Raukamo",
      "Emma Sjöholm",
      "Anniina Tömäjärvi",
      "Mea Vähä-Jaakkola",
    ],
  },
  {
    title: 'Käsikirjoitus',
    members: [
      <strong>Peitsa Leinonen</strong>,
      <strong>Sara Nortunen</strong>,
      "Eetu Airaksinen",
      "Tytti Hyvönen",
      "Juho Jokelainen",
      "Sanna Kallio",
      "Annu Karppinen",
      "Juho Mäntysaari",
      "Antti Niiranen",
      "Lauri Pitkäjärvi",
      "Jemina Raukamo",
      "Miikka Saraniemi",
      "Emmi Tallila",
      "Satu Tammekann",
      "Anniina Tömäjärvi",
    ],
  },
  {
    title: 'Laulunopettaja',
    members: ['Jenni Arponen'],
  },
  {
    title: 'Lavastus & näyttämömestari',
    members: [
      <strong>Utu Metsälä - näyttämömestari</strong>,
      <strong>Daniel Lopez</strong>,
      "Ari Alanko",
      "Alfred Altsten",
      "Niko Haapakoski",
      "Tuomas Hakala",
      "Aleksi Harju",
      "Karoliina Hopia",
      "Sakari Huuskola",
      "Tytti Hyvönen",
      "Aino Hänninen",
      "Venla Junnikkala",
      "Raafael Juntti",
      "Ulrika Kaara",
      "Antti Kemppi",
      "Mariela Krokfors",
      "Matti Kujala",
      "Niko Mikkonen",
      "Antti Niiranen",
      "Sara Nortunen",
      "Lauri Pitkäjärvi",
      "Jemina Raukamo",
      "Kristo Siiskonen",
      "Kiti Suupohja",
      "Petri Sydänmaa",
      "Anniina Tömäjärvi",
      "Kerttu Ylisirniö",
      "Pyry Yli-Sissala",
    ],
  },
  {
    title: 'Lipunmyynti',
    members: [<strong>Jemina Raukamo</strong>,
      "Aino Hänninen",
      "Katja Kujala",
      "Sara Nortunen",
      "Kristo Siiskonen",
      "Anniina Tömäjärvi",
      "Kerttu Ylisirniö",
      "Pyry Yli-Sissala",
    ],
  },
  {
    title: 'Logistiikka',
    members: [
      <strong>Justus Leppäaho</strong>,
      "Ari Alanko",
      "Mikael Andersson",
      "Katja Kujala",
      "Minnow Lappi",
      "Antti Niiranen",
      "Lauri Pitkäjärvi",
      "Sampo Syrjänen",
      "Pyry Yli-Sissala",
    ],
  },
  {
    title: 'Mainosmyynti',
    members: [
      <strong>Henri Kiiski</strong>,
      "Jenni Arponen"
    ],
  },
  {
    title: 'Mainosvideo',
    members: [
      <strong>Iida Roikonen</strong>,
      'Alfred Altsten',
      'Sara Litmanen',
    ],
  },
  {
    title: 'Markkinointi',
    members: [
      <strong>Satu Tammekann</strong>,
      "Alfred Altsten",
      "Argent",
      "Sara Litmanen",
      "Mariela Krokfors",
      "Iida Roikonen",
      "Emma Sjöholm", 
      "Mea Vähä-Jaakkola",
    ],
  },
  {
    title: 'Näyttelijät',
    members: [
      'Jenni Arponen - ', <i>[ Jeanette Lemieux ]</i>,
      'Minea Filén - ', <i>[ Pesukarhu, Murmur (pentu) ]</i>,
      'Ella Inkinen - ', <i>[ Mathilda MacBeth ]</i>,
      'Meiju Koivuniemi - ', <i>[ Hupu ]</i>,
      'Kaisa Kuvaja - ', <i>[ Hunter ]</i>,
      'Peitsa Leinonen - ', <i>[ Murmur, ?!?? ]</i> ,
      'Jenni Nerg - ', <i>[ Dolores "Dolly" Wellington ]</i>,
      'Roope Salonen - ', <i>[ Maxwell J. Gladstone III ]</i>,
      'Jetro Sukkela - ',<i>[ Gary Crooke ]</i>,
      'Satu Tammekann - ', <i>[ Pasi ]</i>,
      'Eedit Taipale - ', <i>[ Elizabeth Carter ]</i>,
      'Onni Tulla - ', <i>[ Landon Poorlie ]</i>,
      'Julia Vuori - ', <i>[ Tupu ]</i>,
    ],
  },
  {
    title: 'Ohjaajat',
    members: [
      <strong>Jyri Siimes</strong>,
      'Juho Mäntysaari - ohjaajakisälli'
      ],
  },
  {
    title: 'Puvustus',
    members: [
      <strong>Inka Inovaara</strong>,
      <strong>Erika Kaski</strong>,
      "Zilla Aittokoski",
      "Alfred Altsten",
      "Ear",
      "Tuomas Hakala",
      "Heidi Hansen-Haug",
      "Matilda Hiukka",
      "Emma Jokela",
      "Venla Junnikkala",
      "Jetro Järvelä",
      "Sanna Kallio",
      "Laura Kastell",
      "Anita Kärkkäinen",
      "Aatos Laapotti",
      "Sanna Laine",
      "Eemil Matikka",
      "Susanna Rouru",
      "Aksu Saarinen",
      "shiona",
      "Emma Taalikka",
      "Emma Tallila",
      "Emilia Uusi-Heikkilä",
    ],
  },
  {
    title: 'Ruokatiimi',
    members: [
      <strong>Miika Kotajärvi</strong>,
      "Argent",
      "Ear",
      "Aino Jokela",
      "Mariela Krokfors",
      "Aatos Laapotti",
      "Antti Niiranen",
    ],
  },
  {
    title: 'Sanoitus',
    members: [
      <strong>Annu Karppinen</strong>,
      "Juho Jokelainen",
      "Laura Kastell",
      "Neea Lumilaakso",
      "Jemina Raukamo",
      "Miikka Saraniemi",
      "Veera Sillanpää",
      "Matti Tiitola",
      "Anniina Tömäjärvi",
    ],
  },
  {
    title: 'Taikatiimi (hiukset & maski)',
    members: [
      <strong>Emma Brown - tukka</strong>,
      <strong>Taika Tikkanen - maski</strong>,
      "Annika Aro",
      "Ella Eskelinen",
      "Henna Herronen",
      "Savu Hiltunen",
      "Tytti Hyvönen",
      "Jetro Järvelä",
      "Juho Jokelainen",
      "Sanna Kallio",
      "Erika Kaski",
      "Anita Kärkkäinen",
      "Viivi Laitinen",
      "Veera Lignell",
      "Iisa Liu",
      "Tam Nhu Rintala",
      "Emmi Tallila",
      "Emmi Vähäkangas",
      "Elsa Virtanen",
    ],
  },
  {
    title: 'Taltiointi',
    members: [
      <strong>Eetu Airaksinen</strong>,
      "Alfred Altsten",
      "Juha-Matti Hakojärvi",
      "Aino Jokela",
      "Anette Karppinen",
      "Annu Karppinen",
      "Olivia Keskinen",
      "Sofi Lemieux",
      "Justus Leppäaho",
      "Daniel Lopez",
      "Titta Tyni",
    ],
  },
  {
    title: 'Tanssijat & koreografit',
    members: [
      <strong>Adelia Aalto</strong>,
      <strong>Marja Seppälä</strong>,
      'Matilda Andersson',
      'Suvi Halonen',
      'Emma Jokela',
      'Ronja Lipsonen',
      'Netta Pihlanen',
      'Milla Puumi',
      <i>Koreografit</i>,
      "Anni Hakala", 
      "Ella Riikonen",
      "Lisa Vilenius",
    ],
  },
  {
    title: 'Tarpeisto',
    members: [
      <strong>Sanna Kallio</strong>,
      <strong>Argent</strong>,
      "Eetu Airaksinen",
      "Ari Alanko",
      "Alfred Altsten",
      "Ear",
      "Lasse Enäsuo",
      "Juha-Matti Hakojärvi",
      "Matilda Hiukka",
      "Tytti Hyvönen",
      "Emma Jokela",
      "Venla Junnikkala",
      "Ulrika Kaara",
      "Laura Kastell",
      "Lauri Kuusisto",
      "Elviira Luukkanen-Lindeman",
      "Niko Mikkonen",
      "Antti Niiranen",
      "Sara Nortunen",
      "Niina Palmu",
      "Papu",
      "Lauri Pitkäjärvi",
      "Jemina Raukamo",
      "Susanna Rouru",
      "shiona",
      "Anniina Tömäjärvi",
      "Elmo von Weissenberg",

    ],
  },
  {
    title: 'Tekniikka',
    members: [
      <strong>Sampo Syrjänen - ääni</strong>,
      <strong>Niko Gullsten - valo</strong>,
      'Sakari Huuskola',
      'Anette Karppinen',
      'Elias Knaapinen',
      'Mikko Kohonen',
      'Jaakko Kronqvist',
      'Minnow Lappi',
      'Justus Leppäaho',
      'Jarkko Liimatainen',
      'Iisa Liu',
      'Aleksi Länkynen',
      'Anniina Paakki',
      'shiona',
      'Pyry Yli-sissala',
    ],
  },
  {
    title: 'Tuottajat',
    members: [
      'Ulrika Kaara',
      'Henri Kiiski',
      'Lauri Pitkäjärvi',
      'Sisu Suominen',
      ],
  },
  {
    title: 'Velhot',
    members: [
      <strong>Ear</strong>,
      "Raafael Juntti",
      "Katja Kujala",
      "Jaakko Kronqvist",
      "shiona",
      "Pyry Yli-Sissala"
    ],
  },
  {
    title: 'Visualistit',
    members: [
      <strong>Emma Taalikka</strong>,
      "Raafael Juntti",
      "Sara Litmanen",
      "Utu Metsälä",
      "Kamilla Mäntynen",
      "Antti Niiranen", 
      "Siina Siuvo",
      "Emmi Tallila",
    ],
  },
  {
    title: 'WWW',
    members: [<strong>Kaisa Silkelä</strong>, 'Taneli Hongisto'],
  },
  {
    title: 'Äänenkäytönopettaja',
    members: ['Olivia Keskinen'],
  },
];

const shows = [
  {
    city: 'Tampere I',
    building: 'Tampere-talo',
    date: 'Ti 12.11.',
    time: '18',
    link: 'https://kauppa.naaspeksi.fi/naaspeksi/kullanvainaja/',
  },
  {
    city: 'Tampere II',
    building: 'Tampere-talo',
    date: 'Ke 13.11.',
    time: '18',
    link: 'https://kauppa.naaspeksi.fi/naaspeksi/kullanvainaja/',
  },
  {
    city: 'Tampere III',
    building: 'Pakkahuone',
    date: 'Ma 18.11.',
    time: '18',
    link:
      'https://www.tiketti.fi/tapahtuma/103549',
    linkTitle: 'Tiketti.fi',
  },
  {
    city: 'Tampere IV',
    building: 'Pakkahuone',
    date: 'Ti 19.11.',
    time: '18',
    link:
      'https://www.tiketti.fi/tapahtuma/103549',
    linkTitle: 'Tiketti.fi',
  },
  {
    city: 'Tampere V',
    building: 'Pakkahuone',
    date: 'Ke 20.11.',
    time: '18',
    link:
      'https://www.tiketti.fi/tapahtuma/103549',
    linkTitle: 'Tiketti.fi',
  },
  {
    city: 'Helsinki',
    building: 'Aleksanterin teatteri',
    date: 'Ma 25.11.',
    time: '18',
    link: 'https://kauppa.naaspeksi.fi/naaspeksi/kullanvainaja/',
  },
];

// --- all required data definitions above ---

const Raina2024 = ({ location }) => (
  <DefaultLayout>
    <Helmet htmlAttributes={{ class: styles.root2024 }} />
    <Metadata
      page={{
        title: 'Kullanvainaja',
        excerpt: 'Rainasivut vuoden 2024 NääsPeksi-produktiolle',
      }}
      location={location}
    />
    <InfoBoard />
  </DefaultLayout>
);

const InfoBoard = () => {
  return (
    <div className={styles.background2024}>
      <div>
        <Header />
        <div >
          <div className={styles.contentStyle2024}>
            <div className={styles.contentBox2024}>
              <SynopsisColumn />
              <ShowsColumn shows={shows} />
              <CrewColumn crew={crew} />
              <SponsorColumn sponsors={sponsors} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Header = () => {
  return (
    <div className={styles.logo2024}>
      <img
        src={titleImg}
        alt="Kullanvainaja"
        title="Kullanvainaja"
        className={styles.titleImg}
        height="17rem"
      />
    </div>
  );
};

const SynopsisColumn = () => {
  return (
    <div className={styles.contentPadding}>
      <div className={styles.synopsisStyle2024}>
        <p>
        Kultakuume. Tuo sokaiseva rikastumisen himo, joka toi tuhansia ihmisiä 
        pieniin kyliin ja metsien siimeksiin ympäri Amerikkaa ja Kanadaa. Myös 
        pienen Stoneboulder Rockin liepeiltä on löydetty kultaa, ja tästä 
        kylästä löydämme nyt myös sankarimme - vuosikymmeniä myöhässä.
        </p>
        <p>
        Jälkijunassa tuleminen ei kuitenkaan ole este eikä mikään nuorelle 
        Carterille, joka luottaa perintönä saatuun (ja huhutusti hyvin 
        kultapitoiseen) valtaukseensa. Isompaa huolta aiheuttaa sen sijaan 
        kilpaileva bisnespohatta Gladstone, eivätkä paikallisetkaan vaikuta 
        järin innostuneilta uusista naamoista. Tonkiminen pintaa syvemmälle 
        saattaakin paljastaa jotain aivan muuta kuin jalometalleja...
        </p>
        <p>
        NääsPeksin 26. produktio on spektaakkeli, jonka jälkeen tunnet olosi 
        kokemusta rikkaammaksi. Tule kanssamme kaivamaan komediakultaa ja 
        todistamaan teatterimuotoa, jota et näe opiskelijatuotannon 
        ulkopuolella!
        </p>
      </div>
    </div>
  );
};

const showColumnStyle = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
};

const ShowsColumn = ({ shows }) => {
  return (
    <div className={[styles.contentPadding]}>
      <h1>Esitykset</h1>

      <p>
        Ovet aukeavat klo 17. Esitykset alkavat tasatunnein, ja ne esitetään
        suomeksi. Esityksen arvioitu kesto on noin 3,5h, mutta sen
        interaktiivisesta luonteesta johtuen, yleisön aktiivisuus vaikuttaa sen
        lopulliseen pituuteen. Esitys sisältää väliajan. Salien istumapaikat
        täytetään vapaasti saapumisjärjestyksessä.
      </p>

      <p>
        Kaipaatko lisätietoja? Ryhmälipputilaus on mahdollinen yli 10 henkilön ryhmälle. Kysymyksiisi vastaa tuottajat@naaspeksi.fi, ja
        lippuasioissa liput@naaspeksi.fi
      </p>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div className={styles.hoverable2024}>
          <a href="https://kauppa.naaspeksi.fi/naaspeksi/kullanvainaja/">
            <h2>Liput</h2>
            <p style={{ margin: '0' }}>25 € Peruslippu</p>
            <p style={{ margin: '0' }}>30 € Paikkalippu*</p>
            <p style={{ margin: '0 0 1em 0' }}>
              15 € Opiskelijat, eläkeläiset, työttömät, varushenkilöt, alle 18-vuotiaat
            </p>
            <p style={{ margin: '0 0 1em 0' }}>
              Narikka (3 €) ei kuulu lipun hintaan.
            </p>
            <p className={styles.lisainfo2024}>*nimetty paikka salin etuosasta</p>
          </a>
        </div>
      </div>

      <div className={styles.showsColumn2024}>
        {shows.map((show, i) => {
          return (
            <Ticket
              city={show.city}
              building={show.building}
              date={show.date}
              time={show.time}
              link={show.link}
              linkTitle={show.linkTitle}
              key={`show-${i}`}
            />
          );
        })}
      </div>
    </div>
  );
};

const Ticket = ({ city, building, date, time, link, linkTitle }) => {
  return (
    <div className={styles.hoverable2024}>
      <a href={link}>
        <h2>{city}</h2>
        <p style={{ margin: '0' }}>
          <strong>
            {date} klo. {time}
          </strong>
        </p>
        <p style={{ margin: '0' }}>
          <strong>{building}</strong>
        </p>
        <p style={{ margin: '0 0 1em 0' }}>
          {(linkTitle ? linkTitle : 'Lippukauppaan') + (link ? ' »' : '')}
        </p>
      </a>
    </div>
  );
};

const CrewSection = ({ title, members }) => (
  <ToggleBox
    title={title}
    defaultOpen={false}
    titleClassName={styles.toggleTitle}
    className={styles.toggleBox}
  >
    <ul>
      {members.map(member => (
        <li id={`${title}-${member}`}>{member}</li>
      ))}
    </ul>
  </ToggleBox>
);

const CrewColumn = ({ crew }) => (
  <div className={styles.contentPadding}>
    <h1>Tekijät</h1>

    <div className={styles.toggleBoxGrid}>
      <div className={styles.columnInner}>
        {crew.slice(0, Math.ceil(crew.length / 3)).map(section => (
          <CrewSection title={section.title} members={section.members} />
        ))}
      </div>

      <div className={styles.columnInner}>
        {crew
          .slice(Math.ceil(crew.length / 3), -Math.floor(crew.length / 3))
          .map(section => (
            <CrewSection title={section.title} members={section.members} />
          ))}
      </div>

      <div className={styles.columnInner}>
        {crew.slice(-Math.floor(crew.length / 3)).map(section => (
          <CrewSection title={section.title} members={section.members} />
        ))}
      </div>
    </div>
  </div>
);

const SponsorColumn = ({ sponsors }) => (
  <div className={styles.synopsisStyle2024}>
    <div className={styles.contentPadding}>
      <h1>Yhteistyössä</h1>

      <div className={styles.adsList}>
        {sponsors.map((sponsor, i) => (
          <Sponsor
            href={sponsor.href}
            src={sponsor.src}
            title={sponsor.title}
            key={`sponsor-${i}`}
            id={`sponsor${i}`}
          />
        ))}
      </div>
    </div>
  </div>
);
const Sponsor = ({ href, src, title, id }) => (
  <div className={styles.adBox} id={id}>
    <a href={href} target="_blank" rel="noopener noreferrer">
      <div className={styles.adImageBox}>
        <img src={src} alt={title} title={title} />
      </div>
    </a>
  </div>
);

export default Raina2024;
